/* DEFAULT */
$baseline: 1.25;
$primary_font: 'Roboto', "Helvetica Neue", sans-serif;
$default-button-radius: 5px;
$default-menu-padding: 6px 0;
$default-button-padding: 8px 16px;
$default-gap: 8px;

/* FONT WEIGHT */
$thin: 100;
$light: 300;
$regular: 400;
$light-bold: 500;
$bold: 700;
$black: 900;

/* FONT SIZE */
$font-xs: 0.714rem; // 10px
$font-sm: 0.875rem; // 13px
$font-default: 1rem; // regular - 14px
$font-md: 1.143rem; // large - 16px
$font-lg: 1.429rem; // h3 - 20px
$font-xlg: 1.786rem; // h2 - 25px
$font-xxlg: 2.143rem; // h1 - 30px

/* BREAKPOINTS */
$breakpoints: (
  xsmall: 480px,
  small: 736px,
  medium: 980px,
  large: 1280px,
  xlarge: 1620px,
  xxlarge: 1690px
);

