@import 'variables';

@mixin heading1() {
  font-style: normal;
  font-size: $font-xxlg;
  font-weight: $bold;
  line-height: 35.16px;
}

@mixin heading2() {
  font-style: normal;
  font-size: $font-xlg;
  font-weight: $bold;
  line-height: 28.13px;
}

@mixin heading3() {
  font-style: normal;
  font-size: $font-lg;
  font-weight: $bold;
  line-height: 25.31px;
}

@mixin body-large-semi-bold() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $light-bold;
  line-height: 22.5px;
}

@mixin body-large-regular() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $regular;
  line-height: 22.5px;
}

@mixin body-large-light() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $light;
  line-height: 22.5px;
}

@mixin body-medium-semi-bold() {
  font-style: normal;
  font-size: $font-default;
  font-weight: $light-bold;
  line-height: 20px;
}

@mixin body-medium-regular() {
  font-style: normal;
  font-size: $font-default;
  font-weight: $regular;
  line-height: 20px;
}

@mixin body-medium-light() {
  font-style: normal;
  font-size: $font-default;
  font-weight: $light;
  line-height: 20px;
}

@mixin body-small-semi-bold() {
  font-style: normal;
  font-size: $font-sm;
  font-weight: $light-bold;
  line-height: 18px;
}

@mixin body-small-regular() {
  font-style: normal;
  font-size: $font-sm;
  font-weight: $regular;
  line-height: 18px;
}

@mixin body-small-light() {
  font-style: normal;
  font-size: $font-sm;
  font-weight: $light;
  line-height: 18px;
}
