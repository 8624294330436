@import "index";
@import "theme/material";

:root {
  font-size: 14px;
}

html {
  font-family: $primary_font;
  font-size: $font-default;
  line-height: $baseline;
}

html,
body {
  position: fixed;

  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }
}

body.mat-typography {
  font-family: $primary_font;
  @include body-medium-regular;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.mat-mdc-menu-panel:not(.classic) {
  min-width: 320px !important;
  max-width: 448px !important;

  &.medium {
    min-width: 280px !important;
    max-width: 280px !important;
  }

  &.small {
    .mat-mdc-menu-item {
      display: flex;
      align-items: center;
      height: 32px;
      min-height: 32px;
    }

    .mat-divider {
      margin: 0.5rem 0;
    }
  }

  &.minified {
    min-width: 112px !important;
    max-width: 280px !important;

    .mdc-list {
      padding: 4px 0;
    }

    .mdc-list-item {
      padding: 0 8px;
      font-size: $font-sm;
    }
  }
}

.cdk-global-overlay-wrapper:has(> .upload-list > mat-bottom-sheet-container) {
  justify-content: end !important; // overrides inline style
}

.upload-list mat-bottom-sheet-container {
  padding: $ratio-xl;
  border-radius: $radius-md;
  background: var(--menu-glassy-background);
  backdrop-filter: $blur-md;
  box-shadow: var(--modal-box-shadow);
  overflow: hidden;
}

mat-spinner.mat-white {
  &.mat-mdc-progress-spinner
    .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--text-on-primary);
  }
}

mat-checkbox .mdc-checkbox__ripple {
  display: none;
}

mat-drawer-container .mat-drawer-backdrop.mat-drawer-shown {
  background-color: var(--modal-backdrop);
}
