/* COLORS */
$white: #FFFFFF;
$dark: #0000007F;
$grey: #5F6368;
$darkgrey: #3C4043;
$lightgrey: #c7c7c7; // border
$background: #f7f9fc;
$empty-background: #fafafa;
$ripple: #e4e4e4;

$blue: #00427b; // header / main button light color
$darkblue: #00004e; // avatar blue / blue font in left menu
$lightblue: #bfcde4; // border blue dashed
$lightblue-transparent: #bfcde466; // left-panel focus color, search not focused
$vaporeonblue: #d2eeff; // left-panel focus color

$error-surface: #FEE8E5;
$error-text: #D21400;

$muted-color: #A9A9A9 ;

$main-radius: 24px;
